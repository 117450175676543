import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from '../modal-content/modal-content.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(public modalService: NgbModal) {

  }

  ngOnInit() {
    this.openModal();
  }

  openModal() {
    const modalRef = this.modalService.open(ModalContentComponent);
    // modalRef.componentInstance.user = this.user;
    modalRef.result.then((result) => {
      if (result) {
        console.log('resultado: ', result);
      }
    });
  }
}