import { Component, OnInit } from '@angular/core';

import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css']
})
export class RodapeComponent implements OnInit {

  public faClock = faClock;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faPhoneAlt = faPhoneAlt;
  public faAt = faAt;

  constructor() { }

  ngOnInit() {
  }

}
